<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import DepartamentsModel from '@/modules/departamnets/departaments-model';

const { fields } = DepartamentsModel;

const store = {
  state: {
    students: [],
    mainSelection: 'students',
    depInfo: '',
    parents: [],
    tests: [],
  },
};

export default {
  name: 'ManagementStudentPage',
  data() {
    return {
      store,
      fields,
      role: window.localStorage.getItem('role'),
      model: {},
    };
  },
  methods: {
    ListStudents() {
      const listStudPath = '/WebListStudentsForBranch.php';
      const data = new FormData();
      data.append('branch_id', this.$route.params.parent_id);
      data.append('branch_type', 'group');
      if (this.model.search !== undefined && this.model.search !== '') {
        data.append('filter', this.model.search);
      }
      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.users) {
            store.state.students = json.users;
          }
        });
    },
    ListTests() {
      const listStudPath = '/WebListTestsResultsForBranch.php';
      const data = new FormData();
      data.append('branch_id', this.$route.params.parent_id);
      if (this.model.search !== undefined && this.model.search !== '') {
        data.append('filter', this.model.search);
      }
      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.tests) {
            store.state.tests = json.tests;
          }
        });
    },
    getDepartament() {
      const getDepPath = '/WebGetBranchDetails.php';

      const data = new FormData();
      data.append('id', parseInt(this.$route.params.parent_id, 10));
      data.append('type', 'group');
      fetch(server + getDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else {
            store.state.depInfo = json;
            store.state.depName = json.name;
            if (json.parents) {
              store.state.parents = json.parents;
            } else {
              store.state.parents = [];
            }
          }
        });
    },
    goTo(mainSelect) {
      switch (mainSelect) {
        case 'students':
          if (store.state.mainSelection !== 'students') {
            store.state.mainSelection = 'students';
            this.$router.push(`/management/student/students-results/${this.$route.params.parent_id}`);
          }
          break;
        case 'file_ai':
          if (store.state.mainSelection !== 'file_ai') {
            store.state.mainSelection = 'file_ai';
            this.$router.push(`/management/student/files-results/${this.$route.params.parent_id}`);
          }
          break;
        case 'tests':
          if (store.state.mainSelection !== 'tests') {
            store.state.mainSelection = 'tests';
            this.$router.push(`/management/student/tests-results/${this.$route.params.parent_id}`);
          }
          break;
        case 'polls':
          if (store.state.mainSelection !== 'polls') {
            store.state.mainSelection = 'polls';
            this.$router.push(`/management/student/polls-results/${this.$route.params.parent_id}`);
          }
          break;
        default:
          if (store.state.mainSelection !== 'students') {
            store.state.mainSelection = 'students';
            this.$router.push(`/management/student/students-results/${this.$route.params.parent_id}`);
          }
          break;
      }
    },
  },
  beforeMount() {
    this.goTo('students');
    this.ListStudents();
    this.getDepartament();
    this.ListTests();
  },
  beforeUpdate() {
    if (this.$route.name.includes('management_student')) {
      store.state.mainSelection = 'students';
    }
    if (this.$route.name.includes('management_test')) {
      store.state.mainSelection = 'tests';
    }
    if (this.$route.name.includes('management_poll')) {
      store.state.mainSelection = 'polls';
    }
  },
};
</script>
<template>
  <div>
    <div>
      <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1" style="width:180px;margin-right: 40px">
          {{ $t("DEPARTAMENTS.LIST_GROUP") }}
        </div>
        <div class="navigation basic-3 subhead-reg-14"
             style="margin: 6px 0;display: flex;align-items: center">
          <unicon name="estate" width="16" height="16" fill="#1694D0"
                  style="cursor: pointer;margin-top: -4px"
                  v-on:click="role.includes('admin')  ?
                  $router.push('/management/department/0') : ''"
                  v-if="store.state.parentName !== '' || store.state.depName !== ''"></unicon>
          <div v-for="(item,i) in store.state.parents" :key="i"
               style="display: inline-flex; width: fit-content">
            <unicon name="angle-right" width="20" height="20" fill="#828282"/>
            <div class="d-inline" style="cursor: pointer;color: #1694D0;width: 100%;height: 20px;
            min-width: max-content"
                 v-on:click="$router.push(
               `/management/${item.parent_of}/${item.id}`)">
              {{item.name}}
            </div>
          </div>

          <unicon name="angle-right" width="20" height="20" fill="#828282"
          />
          <div class="d-inline" style="width: 100%;height: 20px;min-width: max-content">
            {{store.state.depName}}
          </div>
        </div>
        <div  style="width: -webkit-fill-available;">

          <router-link :to="`/student/add/${this.$route.params.parent_id}/0`">
            <BaseButton style="float: right">
              <unicon name="plus" width="16" height="16"
                      style="margin-right: 12px;margin-top: -2px" fill="white"/>
              {{ $t('DEPARTAMENTS.ADD_STUDENT') }}
            </BaseButton>
          </router-link>
          <router-link :to="`/student/import/${this.$route.params.parent_id}`">
            <BaseButton style="float: right; margin-right: 16px">
              <unicon name="plus" width="16" height="16"
                      style="margin-right: 12px;margin-top: -2px" fill="white"/>
              {{ $t('DEPARTAMENTS.IMPORT_STUDENTS') }}
            </BaseButton>
          </router-link>
        </div>

      </div>
    </div>
    <div class="container-group-details">
      <div>
        <div class="group-details-header">
          <div class="main-select">
            <div :class="'subhead-reg-14 basic-1 selector students '
            + (store.state.mainSelection === 'students' ? 'active' : '')"
            v-on:click="goTo('students');"
            style="margin-right: 0">

              <unicon height="20" width="20" name="user-circle" fill="#FDAB3D"></unicon>
              {{ $t("DEPARTAMENTS.STUDENTS") }}

              <div class="number subhead-reg-14">
                {{store.state.students.length}}
              </div>
            </div>
            <div :class="'subhead-reg-14 basic-1 selector tests '
                   + (store.state.mainSelection === 'file_ai' ? 'active' : '')"
                 v-on:click="goTo('file_ai');">

              <unicon height="20" width="20" name="file-check-alt" fill="#1694D0"></unicon>
              {{ $t("GENERAL.FILE_AI") }}

              <div class="number subhead-reg-14">
                {{store.state.students.length}}
              </div>
            </div>
            <!--        <div :class="'subhead-reg-14 basic-1 selector courses '
                   + (store.state.mainSelection === 'courses' ? 'active' : '')"
                        v-on:click="goTo('courses');">

                     <unicon height="20" width="20" name="book-open" fill="#E2445C"></unicon>
                     {{ $t("DEPARTAMENTS.LEARNING_SET") }}
                     <div class="number subhead-reg-14"                  </div>
                   </div>
                   <div :class="'subhead-reg-14 basic-1 selector tests '
                   + (store.state.mainSelection === 'tests' ? 'active' : '')"
                        v-on:click="goTo('tests');">

                     <unicon height="20" width="20" name="file-check-alt" fill="#1694D0"></unicon>
                     {{ $t("TESTS.TESTS") }}

                     <div class="number subhead-reg-14">
                       12
                     </div>
                   </div>
                   <div :class="'subhead-reg-14 basic-1 selector polls '
                   + (store.state.mainSelection === 'polls' ? 'active' : '')"
                        v-on:click="goTo('polls');">

                     <unicon height="20" width="20" name="question-circle" fill="#00CA72"></unicon>
                     {{ $t("GENERAL.POOLS") }}

                     <div class="number subhead-reg-14">
                       4
                     </div>
                   </div>-->
          </div>
          <div class="search-container">
            <div class="custom-input">
              <input type="text"
                     :id="fields.search.name"
                     v-model="model[fields.search.name]"
                     :placeholder="fields.search.label"
                     style="padding-left: 40px"/>
              <unicon fill="#828282" height="16" width="16" name="search"
                      style="cursor: pointer" v-on:click="ListStudents"/>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade-in-up" mode="out-in">
        <router-view/>
      </transition>
      </div>
  </div>
</template>
<style>
.container-group-details {
  //filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
}
.container-group-details .group-details-header{
  padding: 8px;
  margin: 1px 0;
  background: #FFFFFF;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.group-details-header .main-select {
  background: #F5F6F8;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 24px;
}
.group-details-header .main-select .selector,  .second-select .selector {
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
}
.second-select .selector{
  width: 50%;
}
.main-select .unicon, .second-select .unicon {
  height: 20px;
}
.main-select .unicon svg, .second-select .unicon svg{
  margin-top: -4px;
}
.number {
   display: inline-flex;
   padding: 4px 8px;
   border-radius: 6px;
  height: fit-content;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
 }
.main-select .selector.students .number{
  background: rgba(253, 171, 61, 0.1);
  color: #F48C00;
}
.main-select .selector.tests .number{
  background: rgba(67, 83, 255, 0.1);
  color: rgba(67, 83, 255, 1);
}
.main-select .selector.polls .number{
  background: rgba(0, 202, 114, 0.1);
  color: #00CA72;
}
.main-select .selector.courses .number{
  background: rgba(226, 68, 92, 0.1);
  color: #E2445C;
}
.main-select .selector.students.active, .main-select .selector.students:hover{
  background-color: #FFF6D6;
}
.main-select .selector.courses.active, .main-select .selector.courses:hover{
  background-color: #FFE9E9;
}
.main-select .selector.tests.active, .main-select .selector.tests:hover{
  background-color: #E8F4FA;
}
.main-select .selector.polls{
  margin-right: 0px!important;
}
.main-select .selector.polls.active, .main-select .selector.polls:hover{
  background-color: #DBFFEF;
}

.container-group-details .second-select {
  background: #F5F6F8;
  border-radius: 8px;
  display: inline-flex;
  margin-right: 24px;
  width: 100%;
}
.second-select .selector.disable, .second-select .selector.disable:hover{
  background-color: #F2F2F2;
  color: rgba(189, 189, 189, 1);
}
.second-select .selector.active,.second-select .selector:hover {
  background-color: #E8F4FA;
}
.group-details-header .search-container{
  display: inline-flex;
  width: calc(100vw - 583px);
}

.comp {
  height: unset;
  display: inline-flex;
  align-self: center;
}

</style>
